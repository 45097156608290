/* User.css */

.f-700 {
    font-weight: 700;
  }
  
  .mb-4 {
    margin-bottom: 10px; /* You can adjust the value as needed */
    margin-top: 15%;
  }

/* User.css */

/* Tab Navigation Styles */
.tabs {
    display: flex;
    border-bottom: 2px solid #dee2e6;
    margin-bottom: 20px;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
    transition: background-color 0.3s ease;
}

.tab:hover {
    background-color: #f1f1f1;
}

.tab.active {
    border-bottom: 2px solid #007bff;
    font-weight: bold;
    color: #007bff;
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 70px;
  }
  
  .table-search input[type="search"] {
    width: 200px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .btn-secondary {
    background-color: #4a22a1;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
  }
  
  .btn-secondary:hover {
    background-color: #5a6268;
  }
  
  /* .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
  } */
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th, .table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #dee2e6;
  }
  
  .table th {
    background-color: #f8f9fa;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .table-striped tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .text-end {
    text-align: right;
  }
  
  .status {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
  }
  
  .status.Published {
    background-color: #28a745;
  }
  
  .status.Pending {
    background-color: #ffc107;
  }
  
  .status.Rejected {
    background-color: #dc3545;
  }
  

  /******  Add User CSS   *******/
/* User.css */

.container-fluid {
    padding: 20px;
  }
  
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
  
  .card {
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .form-label {
    font-weight: bold;
    margin-top: 40px;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  textarea.form-control {
    resize: vertical;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .alert-danger {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .upload-box {
    border: 2px dashed #ccc;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
  }
  
  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .btn-file {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-file:hover {
    background-color: #0056b3;
  }
  
  Lesson-thumbnail {
    border: 2px dashed #ccc;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
  }
  
  .Lesson-thumbnail img {
    max-width: 100%;
    max-height: 200px;
  }
  
  .float-end {
    float: right !important;
  }
  
  .modal-dialog {
    max-width: 800px;
  }
  
  .modal-body {
    padding: 20px;
  }
  
  .upload-box {
    margin-bottom: 20px;
  }
  
  .btn-min-width {
    min-width: 150px;
    margin-top: 20px;
  }

.style-user{
    margin-top: 50px;
    font-weight: 700;
    margin-bottom: 10px;
}
  

.filter-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filter-row .form-control {
  width: 100%;
  margin-right: 10px;
}

.download-btn {
  background-color: #228B22;
  border-color: #228B22;
  color: white;
  font-weight: 600;
}

.download-btn:hover {
  background-color: #196619;
  border-color: #196619;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.popup-content button {
  margin: 20px;
}

.table-container {
  overflow-x: auto;
  white-space: nowrap;
}