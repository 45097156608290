/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
} */

body{
   padding: 20px;
}

input.inputBox {
  font-size: 10px;
  /* padding: 5px 8px 4px 8px; */
  border-radius: 10px;
  border: 1px solid #666;
}

input.inputBox1 {
  font-size: 10px;
  padding: 5px 8px 4px 8px;
  border-radius: 10px;
  border: 1px solid #666;
}

.calendarWrap {
  display: inline-block;
  position: relative;
}

.calendarElement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  border: 1px solid #ccc;
  z-index: 999;
}

/* custom-date-range-picker.css */

.calendarElement .rdrCalendarWrapper {
  width: 250px; /* Set your desired width */
  height: 350px; /* Set your desired height */
  overflow: hidden;
}

.calendarElement .rdrMonths {
  display: flex;
  flex-direction: column;
  
}

.calendarElement .rdrMonth {
  width: 100%;
}

.calendarElement .rdrMonthAndYearWrapper,
.calendarElement .rdrMonthPicker,
.calendarElement .rdrYearPicker,
.calendarElement .rdrWeekDays,
.calendarElement .rdrDays {
  font-size: 0.8em; /* Adjust font size if necessary */
}

.calendarElement .rdrDay {
  width: 30px; /* Set your desired day cell width */
  height: 30px; /* Set your desired day cell height */
}

.calendarElement .rdrDayNumber span {
  line-height: 30px; /* Match this to the day cell height */
}
