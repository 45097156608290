.login-container {
    height: 100vh;
    background-color: #f0f2f5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .login-row {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  
  .login-form-section {
    background-color: white;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .login-form-section .main_logo {
    width: 150px;
  }
  
  .login-form-section h3 {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .login-form-section p {
    font-size: 14px;
    color: #666;
  }
  
  .form-group {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .form-group label {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-group .password-toggle-btn {
    margin-top: 10px;
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
  }
  
  .phone-input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .phone-input-group .form-control {
    margin-top: 10px;
  }
  
  .btn {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
  }
  
  .alert {
    margin-top: 20px;
    width: 100%;
  }
  
  .login-image-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .login-image-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  